<template>
  <div class="footer-partners">
    <a href="https://top-cat.org" title="TopCat" target="_blank" class="footer-partner-logo footer-partner-logo--topcat" rel="nofollow"></a>
    <a href="http://kotipes58.ru/" title="Зооцентр Кот и Пес" target="_blank" class="footer-partner-logo footer-partner-logo--catsdog" rel="nofollow"></a>
    <a href="https://kogtemania.ru/" title="Когтемания" target="_blank" class="footer-partner-logo footer-partner-logo--kogtemania" rel="nofollow"></a>
  </div>
</template>

<script>
export default {
  name: 'FooterPartners',
};
</script>

<style lang="scss" scoped>
@import '~@/styles/palette.scss';

.footer-partners {
  display: flex;

  .footer-partner-logo {
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
    background-image: url('./images/elitar_partners.png');
    background-size: 812px 70px;

    &:last-child {
      margin-right: 0;
    }

    &--topcat {
      background-position: -59px 0;
      width: 114px;
      height: 70px;

      &:hover {
        background-position: -465px 0;
      }
    }

    &--catsdog {
      background-position: -173px 0;
      width: 67px;
      height: 70px;

      &:hover {
        background-position: -580px 0;
      }
    }

    &--kogtemania {
      background-position: -242px 0;
      width: 163px;
      height: 70px;

      &:hover {
        background-position: -649px 0;
      }
    }
  }
}

@media (min-width: 1024px) {
  .footer-partners {
    .footer-partner-logo {
      margin-right: 25px;
      background-size: 1140px 100px;

      &--topcat {
        background-position: -83px 0;
        width: 160px;
        height: 100px;

        &:hover {
          background-position: -653px 0;
        }
      }

      &--catsdog {
        background-position: -241px 0;
        width: 100px;
        height: 100px;

        &:hover {
          background-position: -812px 0;
        }
      }

      &--kogtemania {
      background-position: -337px 0;
      width: 232px;
      height: 100px;

      &:hover {
        background-position: -908px 0;
      }
    }
    }
  }
}
</style>
